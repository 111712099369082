import "./styles.scss";
import React, { useState } from "react";
import AppConfig from "../../helpers/config";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SignInModal from "../Modals/SignInModal";
import SignUpModal from "../Modals/SignUpModal";
import Dropdown from "react-bootstrap/Dropdown";
import SuccessModal from "../Modals/SuccessModal";
import { useDispatch, useSelector } from "react-redux";
import SunIcon from "../../assets/images/icons/Sun.svg";
import Genius from "../../assets/images/chat-page-app-icon.svg";
import Logo from "../../assets/images/logo-main-36h.svg";
import GlobeIcon from "../../assets/images/icons/icons-Globe-Line.svg";
import {
  getSearchFilters,
  setSelectedPackage,
} from "../../redux/slices/searchFilterSlice";
import {
  getLoginConfig,
  setIsLogin,
  setLoginUser,
  setDarkMode,
  changeAppLocale, changeAppCurrency,
} from "../../redux/slices/loginconfigSlice";
import {
  getAppConfig,
  getHotels,
  setModals,
} from "../../redux/slices/appconfigSlice";
import {
  getCurrencyBadge,
  getCurrencyName,
  getSearchOnlyListingParams,
} from "../../helpers/common";
import ForgotPasswordModal from "../Modals/ForgotPasswordModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faTimes } from "@fortawesome/pro-light-svg-icons";

const AppHeader = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appConfig = useSelector(getAppConfig);
  const searchfilters = useSelector(getSearchFilters);
  const loginconfig = useSelector(getLoginConfig);

  const { modals } = appConfig;
  const [showMenu, setShowMenu] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);

  const showMenuFun = () => setShowMenu(true);
  const hideMenu = () => setShowMenu(false);

  const handleHideModal = (type) =>
    dispatch(setModals({ ...modals, [type]: false }));
  const handleShowModal = (type) =>
    dispatch(setModals({ ...modals, [type]: true }));

  const darkModeHandler = () => {
    dispatch(setDarkMode(!loginconfig.darkMode));
  };

  const navigateToSearchPage = (_package) => {
    dispatch(setSelectedPackage(_package));
    navigate(
      "/search/" +
        _package.guid +
        "/query?page=1" +
        "&locale=" +
        loginconfig?.locale?.code +
        "&currency=" +
        loginconfig?.currency?.code
    );

    const params = getSearchOnlyListingParams({
      guid: _package.guid,
      page: 1,
      searchfilters,
      loginconfig,
      initialSearch: true,
    });

    dispatch(setSelectedPackage(_package));
    dispatch(getHotels(params));
  };

  const handleLogout = () => {
    dispatch(setIsLogin(false));
    dispatch(setLoginUser({}));
    localStorage.removeItem("auth_token");
  };

  return (
    <>
      <header id="header" className="header-for-desktop">
        <nav className="navbar">
          <div className="container">
            <a className="navbar-brand" onClick={() => navigate("/")}>
              <img src={Logo} alt="Logo" />
            </a>
            <a className="menu-toggler">
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </a>
            <div className="custom-menu">
              <div className="discover-dropdown">
                <Dropdown>
                  <Dropdown.Toggle>{t("AppHeader.Discover")}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {appConfig?.packageTypes?.map((_package, index) => {
                      return (
                        <Dropdown.Item
                          key={index}
                          onClick={() => navigateToSearchPage(_package)}
                          active={
                            _package?.guid ===
                            searchfilters?.selectedPackage?.guid
                          }
                        >
                          {_package?.type?.replace('*', ' ⭐')}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <ul className="menu-items">
                <li>
                  <a
                      className="menu-link"
                      onClick={() => navigate('/')}
                  >
                    {t("Home")}
                  </a>
                </li>
                <li>
                  <a
                      className="menu-link"
                      onClick={() => navigate('/about-us')}
                  >
                    {t("AppHeader.About")}
                  </a>
                </li>
                <li>
                  <a className="menu-link" onClick={darkModeHandler}>
                    {loginconfig.darkMode ? (
                        <img src={SunIcon} className="icon" alt={"sun-icon"}/>
                    ) : (
                        <FontAwesomeIcon className="icon" icon={faMoon}/>
                    )}
                    {loginconfig.darkMode ? t("LightMode") : t("DarkMode")}
                  </a>
                </li>
                <li className="language-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img
                          src={GlobeIcon}
                          className="icon"
                          alt={"globe-icon"}
                      />
                      {loginconfig?.locale?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu onChange={(e) => console.log(e)}>
                      <h6>{t("Language")}</h6>
                      {AppConfig.appLanguages.map((language, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={() => dispatch(changeAppLocale(language))}
                                active={language?.id === loginconfig?.locale?.id}
                            >
                              {language.name}
                            </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="language-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <span style={{color: "#BDC6D7"}}>
                        {getCurrencyBadge(loginconfig?.currency?.code)}
                      </span>{" "}
                      {loginconfig?.currency?.code}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <h6>{t("Currency")}</h6>
                      {AppConfig.currencies.map((currency, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={() => dispatch(changeAppCurrency(currency))}
                                active={currency?.code === loginconfig?.currency?.code}
                            >
                              {`${currency?.symbol} ${currency?.name?.toUpperCase()}`}
                            </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="user-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src={Genius} alt={"genius"}/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate("/me/favorites")}>
                        {t("AppHeader.MyFavourites")}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => navigate("/me/settings")}>
                        {t("Settings")}
                      </Dropdown.Item>
                      <Dropdown.Item className="btn-holder">
                        <div className="auth-action">
                          <a
                              onClick={() =>
                                  loginconfig.isLogin
                                      ? navigate("/me/account")
                                      : handleShowModal("register")
                              }
                          >
                            {t("AppHeader.Account")}
                          </a>
                        </div>
                        <div className="auth-action">
                          <a
                              className="transparent-btn"
                              onClick={() =>
                                  loginconfig.isLogin
                                      ? handleLogout()
                                      : handleShowModal("login")
                              }
                          >
                            {loginconfig.isLogin ? t("Logout") : t("Login")}
                          </a>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <header id="header" className="header-for-mobile">
        <nav className="navbar">
          <div className="container">
            <a onClick={showMenuFun} className="menu-toggler">
              <span className="bar1"></span>
              <span className="bar2"></span>
              <span className="bar3"></span>
            </a>
            <a className="navbar-brand" onClick={() => navigate("/")}>
              <img src={Logo} alt="Logo" />
            </a>
            <div
              className={
                showMenu ? "custom-menu-small show" : "custom-menu-small"
              }
            >
              <a onClick={hideMenu} className="close-menu">
                <FontAwesomeIcon icon={faTimes} />
              </a>
              <ul className="menu-items">
                <li className="discover-dropdown">
                  <Dropdown>
                    <Dropdown.Toggle>{t("AppHeader.Discover")}</Dropdown.Toggle>
                    <Dropdown.Menu>
                      {appConfig?.packageTypes?.map((_package, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => navigateToSearchPage(_package)}
                            active={
                              _package?.guid ===
                              searchfilters?.selectedPackage?.guid
                            }
                          >
                            {_package?.type?.replace('*', ' ⭐')}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li>
                  <button
                    className="menu-link text-start"
                    onClick={() => navigate('/about-us')}
                  >
                    {t("AppHeader.About")}
                  </button>
                </li>
                <li>
                  <button
                    className="menu-link text-start"
                    onClick={() => window.open(`mailto:info@genius.travel`)}
                  >
                    {t("AppFooter.Contact")}
                  </button>
                </li>
              </ul>
              <div className="small-header-action">
                <div className="language-dropdown action-holder">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <img src={GlobeIcon} className="icon" alt={"glob-icon"} />
                      {loginconfig?.locale?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu onChange={(e) => console.log(e)}>
                      {AppConfig.appLanguages.map((language, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => dispatch(changeAppLocale(language))}
                            active={language?.id === loginconfig?.locale?.id}
                          >
                            {language.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="currancy-dropdown action-holder">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <span>
                        {getCurrencyBadge(loginconfig?.currency?.code)}
                      </span>
                      {getCurrencyName(loginconfig?.currency?.code)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {AppConfig.currencies.map((currency, index) => {
                        return (
                            <Dropdown.Item
                                key={index}
                                onClick={() => dispatch(changeAppCurrency(currency))}
                                active={currency?.code === loginconfig?.currency?.code}
                            >
                              {`${currency?.symbol} ${currency?.name?.toUpperCase()}`}
                            </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="action-holder">
                  <a className="darkMode-btn" onClick={darkModeHandler}>
                    {loginconfig.darkMode ? (
                      <img src={SunIcon} className="icon" alt={"sun-icon"} />
                    ) : (
                      <FontAwesomeIcon icon={faMoon} className="icon" />
                    )}
                    {loginconfig.darkMode ? "Light Mode" : "Dark Mode"}
                  </a>
                </div>
              </div>
            </div>
            <div className="user-dropdown">
              <Dropdown>
                <Dropdown.Toggle>
                  <img src={Genius} alt={"genius"} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("/me/favorites")}>
                    {t("AppHeader.MyFavourites")}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/me/settings")}>
                    {t("Settings")}
                  </Dropdown.Item>
                  <Dropdown.Item className="btn-holder">
                    <div className="auth-action">
                      <a
                        onClick={() =>
                          loginconfig.isLogin
                            ? navigate("/me/account")
                            : handleShowModal("register")
                        }
                      >
                        {t("AppHeader.Account")}
                      </a>
                    </div>
                    <div className="auth-action">
                      <a
                        className="transparent-btn"
                        onClick={() =>
                          loginconfig.isLogin
                            ? handleLogout()
                            : handleShowModal("login")
                        }
                      >
                        {loginconfig.isLogin ? t("Logout") : t("Login")}
                      </a>
                    </div>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </nav>
      </header>
      <SignUpModal
        show={modals.register}
        onHide={() => handleHideModal("register")}
      />
      <SignInModal
        show={modals.login}
        onHide={() => handleHideModal("login")}
        showForgotModal={() => setShowForgotModal(true)}
      />
      <SuccessModal
        show={modals.confirmation}
        onHide={() => handleHideModal("confirmation")}
      />
      <ForgotPasswordModal
        show={showForgotModal}
        onHide={() => setShowForgotModal(false)}
      />
    </>
  );
};

export default AppHeader;
