import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./App.css";
import React, {useEffect, useState} from "react";

import {GoogleOAuthProvider} from '@react-oauth/google';

import {useDispatch, useSelector} from "react-redux";
import AppRoutes from "./routes";
import Loader from "./components/Loader/Loader";
import {useTranslation} from "react-i18next";
import {ToastContainer, toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    changeAppCurrency,
    changeAppLocale,
    getLoginConfig,
    resetLoginConfigState
} from "./redux/slices/loginconfigSlice";
import moment from "moment";
import localization from "moment/locale/nl";
import {
    getSearchFilters, resetSearchFiltersState,
    setTravelDate,
} from "./redux/slices/searchFilterSlice";
import TransactionWrapper from "./container/TransactionWrapper";
import {isMobile, isTablet} from 'react-device-detect';
import ScrollLock from "./components/ScrollLock";
import DownloadApp from "./components/DownloadApp";
import AppConfig from "./helpers/config";
import {resetAppConfigState} from "./redux/slices/appconfigSlice";
import {resetBookingSliceState} from "./redux/slices/bookingSlice";
// import countryToCurrency from "country-to-currency";


const usTimezones = [
    'America/New_York',
    'America/Chicago',
    'America/Denver',
    'America/Los_Angeles',
    'America/Anchorage',
    'Pacific/Honolulu',
];

function App() {
    const {i18n} = useTranslation();
    const dispatch = useDispatch();
    const appconfig = useSelector((state) => state.appconfig);
    const loginConfig = useSelector(getLoginConfig);
    const searchfilters = useSelector(getSearchFilters);
    const [showDownloadSection, setShowDownloadSection] = useState(true);

    const resetAllSlices = () => (dispatch) => {
        dispatch(resetAppConfigState());
        dispatch(resetBookingSliceState());
        dispatch(resetLoginConfigState());
        dispatch(resetSearchFiltersState());
    };

    useEffect(() => {
        const savedVersion = localStorage.getItem('gt_app_version');
        const browserLang = navigator.language;
        const browserLocale = browserLang.split('-')[0];
        const browserCountry = browserLang.split('-')[1] || ''; // Handle case where there is no region part
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        console.log({browserLang})

        // Fresh app start logic
        if (!loginConfig?.locale?.code) {
            console.log('Locale not found so setting locale.');
            // Check if the user's timezone is one of the US timezones
            if (usTimezones.includes(userTimezone)) {
                console.log('US time zone found, setting english and dollar');
                dispatch(changeAppLocale(AppConfig.appLanguages.find(lang => lang.code === 'en_US')));
                dispatch(changeAppCurrency(AppConfig.currencies.find(currency => currency.code === 'USD')));
            } else {
                // Setting user default language
                const langObject = AppConfig.appLanguages.find(f => f.code.split('_')[1] === browserCountry ||
                        f.code.split('_')[0] === browserLocale) ||
                    AppConfig.appLanguages.find(f => f.code === 'en_US');
                dispatch(changeAppLocale(langObject));

                // Determine the currency
                // let currencyObject;
                // if (langObject?.code === 'en_GB') {
                //     // UK locale, set pound currency
                //     currencyObject = AppConfig.currencies.find(c => c.code === 'GBP');
                // } else {
                // Default to Euro for all other cases
                const currencyObject = AppConfig.currencies.find(c => c.code === 'EUR');
                // }

                dispatch(changeAppCurrency(currencyObject));
                console.log(`Another timezone found, setting web locale ${langObject?.name} and ${currencyObject.code}`);
            }
        }

        if (!savedVersion || savedVersion !== AppConfig.webVersion) {
            // Version mismatch, reset the Redux store
            dispatch(resetAllSlices());
            window.location.href = '/';
            // Update the stored version to the current version
            localStorage.setItem('gt_app_version', AppConfig.webVersion);
        }

    }, [dispatch]);

    useEffect(() => {
        document.body.id = loginConfig.darkMode ? "darkMode" : "";
    }, [loginConfig]);

    useEffect(() => {
        i18n.changeLanguage(loginConfig?.locale?.short?.toLowerCase());
        moment.updateLocale(
            loginConfig?.locale?.short?.toLowerCase(),
            localization
        );
        return () => {
            if (
                searchfilters?.travelDate?.startDate &&
                new Date(searchfilters?.travelDate?.startDate) <= new Date()
            ) {
                dispatch(setTravelDate({startDate: "", endDate: ""}));
            }
        };
    }, [loginConfig.locale]);

    useEffect(() => {
        if (appconfig.error) {
            toast.error(appconfig.error);
        }
    }, [appconfig.error]);

    return (
        <GoogleOAuthProvider clientId="752153235622-du75254s7dkvo8l472g3ib552umv4pmt.apps.googleusercontent.com">
            <TransactionWrapper>
                <AppRoutes/>
                {showDownloadSection && (isMobile || isTablet) &&
                    <DownloadApp setShowDownloadSection={setShowDownloadSection}/>}
                {appconfig?.loadingScreen &&
                    <ScrollLock isOpen={appconfig?.loadingScreen}>
                        <Loader/>
                    </ScrollLock>
                }
                <ToastContainer
                    position="bottom-right"
                    autoClose={4000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </TransactionWrapper>
        </GoogleOAuthProvider>
    );
}

export default App;
