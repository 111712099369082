import "./style.scss";
import React, {useState} from "react";
import {Dropdown, Accordion} from "react-bootstrap";
import Apple from "../../assets/images/Apple-appstore.png";
import Logo from "../../assets/images/logo-main-36h.svg";
import Expedia from "../../assets/images/icons/expedia.svg";
import LogoDark from "../../assets/images/icons/logo-main-dark.svg";
import ExpediaDark from "../../assets/images/icons/expedia-logo-dark.svg";
import Android from "../../assets/images/Google-appstore.png";
import SunIcon from "../../assets/images/icons/Sun.svg";
import CatImage from "../../assets/images/logo-white-bg.svg";
import GlobIcon from "../../assets/images/icons/icons-Globe-Line.svg";
import {useTranslation} from "react-i18next";
import {getCurrencyBadge} from "../../helpers/common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {
    changeAppCurrency,
    changeAppLocale,
    getLoginConfig,
    setDarkMode,
} from "../../redux/slices/loginconfigSlice";
import AppConfig from "../../helpers/config";
import {faGlobe, faMoon, faSunBright} from "@fortawesome/pro-light-svg-icons";
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons";
import {Link, useNavigate} from "react-router-dom";

const AppFooter = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loginconfig = useSelector(getLoginConfig);
    const [email, setEmail] = useState("");

    const getUrl = (city) =>
        `https://city.geniustravel.com/${city}/?languageID=en-GB`;

    const cities = [
        {name: "Amsterdam", link: getUrl("Amsterdam")},
        {name: "Bali", link: getUrl("Bali")},
        {name: "Bangalore", link: getUrl("Bangalore")},
        {name: "Bangkok", link: getUrl("Bangkok")},
        {name: "Barcelona", link: getUrl("Barcelona")},
        {name: "Belgrade", link: getUrl("Belgrade")},
        {name: "Bordeaux", link: getUrl("Bordeaux")},
        {name: "Brussels", link: getUrl("Brussels")},
        {name: "Delhi", link: getUrl("Delhi")},
        {name: "Dubai", link: getUrl("Dubai")},
        {name: "Edinburgh", link: getUrl("Edinburgh")},
        {name: "Geneva", link: getUrl("Geneva")},
        {name: "Goa", link: getUrl("Goa")},
        {name: "Ibiza", link: getUrl("Ibiza")},
        {name: "Lisbon", link: getUrl("Lisbon")},
        {name: "London", link: getUrl("London")},
        {name: "Monaco", link: getUrl("Monaco")},
        {name: "Mumbai", link: getUrl("Mumbai")},
        {name: "New York", link: getUrl("New-York")},
        {name: "Paris", link: getUrl("Paris")},
        {name: "Phuket", link: getUrl("Phuket")},
        {name: "Rajasthan", link: getUrl("Rajasthan")},
        {name: "Rome", link: getUrl("Rome")},
        {name: "San Francisco", link: getUrl("San-Francisco")},
        {name: "Venice", link: getUrl("Venice")},
    ];

    return (
        <footer id="footer" className="footer-wrapper">
            <div className="footer-top">
                <div className="container">
                    <img src={CatImage} className="cat-icon" alt={"cat-icon"}/>
                    <div className="row">
                        <h6
                            className="fs-16 gray-text bold footer-title"
                            style={{marginBottom: "2rem"}}
                        >
                            Citytips
                        </h6>
                        <div className="col-md-3 for-desktop">
                            <div className="footer-items">
                                <ul className="link-items">
                                    {cities.slice(0, 7).map((city, index) => {
                                        return (
                                            <li key={index + city}>
                                                <a
                                                    className="gray-text"
                                                    href={city.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {city?.name}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 for-desktop">
                            <div className="footer-items">
                                <ul className="link-items">
                                    {cities.slice(7, 14).map((city, index) => {
                                        return (
                                            <li key={index + city}>
                                                <a
                                                    className="gray-text"
                                                    href={city.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {city?.name}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 for-desktop">
                            <div className="footer-items">
                                <ul className="link-items">
                                    {cities.slice(14, 21).map((city, index) => {
                                        return (
                                            <li key={index + city}>
                                                <a
                                                    className="gray-text"
                                                    href={city?.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {city?.name}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 for-desktop">
                            <div className="footer-items">
                                <ul className="link-items">
                                    {cities.slice(21, cities.length).map((city, index) => {
                                        return (
                                            <li key={index + city}>
                                                <a
                                                    className="gray-text"
                                                    href={city.link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {city?.name}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 for-mobile">
                            <div className="footer-items">
                                <div className="footer-dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle>New York</Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {cities.map((city, index) => (
                                                <Dropdown.Item
                                                    key={index + city}
                                                    onClick={() => window.open(city?.link, "_blank")}
                                                >
                                                    {city?.name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="footer-items">
                                <h6 className="fs-16 gray-text bold footer-title">
                                    {t("AppFooter.Contact")}
                                </h6>
                                <ul className="link-items">
                                    <li>
                                        <a
                                            className="gray-text"
                                            onClick={() => window.open(`mailto:info@genius.travel`)}
                                        >
                                            {t("AppFooter.Contact")}
                                        </a>
                                    </li>
                                    <li>
                                        <Link className="gray-text" to="/faqs">
                                            {t("AppFooter.FAQ")}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="footer-items">
                                <h6 className="fs-16 gray-text bold footer-title">Genius</h6>
                                <ul className="link-items">
                                    <li>
                                        <a
                                            className="gray-text"
                                            onClick={() =>
                                                navigate('/about-us')
                                            }
                                        >
                                            {t("AppFooter.AboutUs")}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="gray-text"
                                            onClick={() =>
                                                navigate('/press')
                                            }
                                        >
                                            {t("AppFooter.Press")}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            className="gray-text"
                                            onClick={() =>
                                                navigate('/vacancies')
                                            }
                                        >
                                            {t("AppFooter.Vacancies")}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-3 col-6">
                            <div className="footer-items">
                                <h6 className="fs-16 gray-text bold footer-title">
                                    {t("AppFooter.DownloadOurApp")}
                                </h6>
                                <div className="app-actions">
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.appelit.geniustravel"
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                    >
                                        <img src={Android} alt={"android-picture"}/>
                                    </a>
                                    <a
                                        href="https://apps.apple.com/us/app/genius/id1661100442"
                                        target={"_blank"}
                                        rel="noopener noreferrer"
                                    >
                                        <img src={Apple} alt={"apple-picture"}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 col-12">
                            <div className="footer-items">
                                <h6 className="fs-16 gray-text bold footer-title">
                                    {t("AppFooter.WantToHaveExclusive")}
                                </h6>
                                <form
                                    className="newsletter"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        window.open(
                                            `mailto:${email}?subject=News Letter Subscriber`
                                        );
                                    }}
                                >
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder={t("AppFooter.EmailAddress")}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required={true}
                                    />
                                    <button type="submit">{t("Buttons.Send")}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-middle">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-7 col-12">
                            <p className="powered-by-text">
                                <img
                                    src={loginconfig.darkMode ? Logo : LogoDark}
                                    alt={"logo"}
                                    style={{width: "86px"}}
                                />{" "}
                                {t("AppFooter.PoweredBy")} expedia{" "}
                                <img
                                    src={loginconfig.darkMode ? Expedia : ExpediaDark}
                                    alt={"expedia"}
                                />
                            </p>
                        </div>
                        <div className="col-lg-6 col-md-5 col-12 hide-on-tab">
                            <div className="footer-action">
                                <ul>
                                    <li>
                                        <button
                                            className="action-btn dropdown-toggle"
                                            onClick={() =>
                                                dispatch(setDarkMode(!loginconfig.darkMode))
                                            }
                                        >
                                            {loginconfig.darkMode ? (
                                                <FontAwesomeIcon icon={faSunBright}/>
                                            ) : (
                                                <FontAwesomeIcon icon={faMoon}/>
                                            )}
                                            {loginconfig.darkMode ? t("LightMode") : t("DarkMode")}
                                        </button>
                                    </li>
                                    <li>
                                        <Dropdown>
                                            <Dropdown.Toggle>
                                                <FontAwesomeIcon icon={faGlobe}/>
                                                {loginconfig?.locale?.name}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <h6>{t("Language")}</h6>
                                                {AppConfig.appLanguages.map((language, index) => {
                                                    return (
                                                        <Dropdown.Item
                                                            key={index}
                                                            onClick={() =>
                                                                dispatch(changeAppLocale(language))
                                                            }
                                                            active={language?.id === loginconfig?.locale?.id}
                                                        >
                                                            {language.name}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                    <li>
                                        <Dropdown>
                                            <Dropdown.Toggle>
                                                <span style={{color: "#BDC6D7"}}>
                                                    {getCurrencyBadge(loginconfig?.currency?.code)}
                                                </span>{" "}
                                                {loginconfig?.currency?.code}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <h6>{t("Currency")}</h6>
                                                {AppConfig.currencies.map((currency, index) => {
                                                    return (
                                                        <Dropdown.Item
                                                            key={index}
                                                            onClick={() => dispatch(changeAppCurrency(currency))}
                                                            active={currency?.code === loginconfig?.currency?.code}
                                                        >
                                                            {`${currency?.symbol} ${currency?.name?.toUpperCase()}`}
                                                        </Dropdown.Item>
                                                    );
                                                })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-5 col-12 for-tabs">
                            <div className="footer-dropdown">
                                <Dropdown>
                                    <Dropdown.Toggle>{t("Settings")}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() =>
                                                dispatch(setDarkMode(!loginconfig.darkMode))
                                            }
                                        >
                                            {loginconfig.darkMode ? (
                                                <img src={SunIcon} className="icon" alt={"sun-icon"}/>
                                            ) : (
                                                <FontAwesomeIcon icon={faMoon}/>
                                            )}
                                            {loginconfig.darkMode ? t("LightMode") : t("DarkMode")}
                                        </Dropdown.Item>
                                        <Accordion className="dropdown-accordion">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    <img
                                                        src={GlobIcon}
                                                        className="icon"
                                                        alt={"globe-icon"}
                                                    />
                                                    {loginconfig?.locale?.name}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <h6>{t("Language")}</h6>
                                                    {AppConfig.appLanguages.map((language, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={index}
                                                                onClick={() =>
                                                                    dispatch(changeAppLocale(language))
                                                                }
                                                                active={language?.id === loginconfig?.locale?.id}
                                                            >
                                                                {language.name}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                          <span
                              style={{color: "#BDC6D7", marginRight: "5px"}}
                          >
                            {getCurrencyBadge(
                                loginconfig?.currency?.code
                            )}
                          </span>
                                                    {loginconfig?.currency?.code}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <h6>{t("Currency")}</h6>
                                                    {AppConfig.currencies.map((currency, index) => {
                                                        return (
                                                            <Dropdown.Item
                                                                key={index}
                                                                onClick={() => dispatch(changeAppCurrency(currency))}
                                                                active={currency?.code === loginconfig?.currency?.code}
                                                            >
                                                                {`${currency?.symbol} ${currency?.name?.toUpperCase()}`}
                                                            </Dropdown.Item>
                                                        );
                                                    })}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-sm-9">
                            <ul className="static-pages-link">
                                <li>
                                    <a>
                                        {t("AppFooter.Copyright")} © {new Date().getFullYear()}{" "}
                                        Genius
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => navigate(`/privacy-policy/query?locale=${loginconfig?.locale?.code}&external=false`)}>
                                        {t("AppFooter.PrivacyPolicy")}
                                    </a>
                                </li>
                                <li>
                                    <a onClick={() => navigate(`/terms-conditions`)}>
                                        {t("AppFooter.TermsAndConditions")}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-sm-3">
                            <ul className="social-links">
                                <li>
                                    <a>
                                        <FontAwesomeIcon icon={faFacebookF}/>
                                    </a>
                                </li>
                                <li>
                                    <a>
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default AppFooter;
